<template>
	<div id="QRCode">
        <div @click="getQRCodeAll()"><i class="iconfont iconsaoma"></i></div>
		<div id="bcidall"></div>
		<div class="saomaxxall" v-if="showSao">
			<div class="left" @click="close"><i class="iconfont iconguanbi"></i></div>
			<div class="right" @click="setFlash"><i :class="Flash==true?'iconfont iconshoudiantongkai':'iconfont iconshoudiantongguan'"></i></div>
		</div>
    </div>
</template>
<script>
export default {
	data() {
		return {
            showSao:false,
            Flash:false,
		}
	},
	methods:{
		close() {
			var b = plus.barcode.getBarcodeById("bcidall");
			if (b) {
				this.showSao = false;
				b.close();
				b.cancel();
			} else {
			}
		},
		setFlash() {
            const that=this
			var b = plus.barcode.getBarcodeById("bcidall");
            if (b) {
                if (!that.Flash) {
                    that.Flash = true;
                }else{
                    that.Flash = false;
                }
			    b.setFlash(that.Flash);
			} else {
				console.log("find failed!");
			}
		},
        getQRCodeAll (){
			const that = this;
            let height = document.body.clientHeight - 50;
            let barcode = null
            that.showSao = true;
            barcode = plus.barcode.create(
                "bcidall",
                [plus.barcode.EAN13, plus.barcode.EAN8],
                {
                    top: "0",
                    left: "0",
                    width: "100vw",
                    height: height + "px",
                    position: "static",
                    frameColor: "#60c281",
                    scanbarColor: "#60c281",
                }
            );
            //成功回调
            barcode.onmarked = function onmarked(type, result, file) {
                switch (type) {
                    case plus.barcode.QR:
                        type = "QR";
                        break;
                    case plus.barcode.EAN13:
                        type = "EAN13";
                        break;
                    case plus.barcode.EAN8:
                        type = "EAN8";
                        break;
                    default:
                        type = "其它" + type;
                        break;
                }
                result = result.replace(/\n/g, "");
                console.log("扫描成功", result);
				that.$emit("result",result);
                that.close()
                barcode.close();
                barcode.cancel();
            };
            plus.webview.currentWebview().append(barcode);
            barcode.start();
        }

	},
	mounted(){},
    computed: {},
    watch: {},
}
</script>
<style lang="less">
#QRCode{
	.saomaxxall {
		width: 100%;
		height: 50px;
		z-index: 9999;
		background: var(--themeColor);
		position: fixed;
		bottom: 0;
        left: 0;
        right: 0;
		display: flex;
		color: #fff;
        font-size: 16px;
        i{
            font-size: 23px;
        }
		.left {
			height: 50px;
			line-height: 50px;
			text-align: center;
            width: 50%;
            border-right: 1px solid #fff;
		}
		.right {
			text-align: center;
			width: 50%;
			line-height: 50px;
			height: 50px;
		}
	}

}
</style>